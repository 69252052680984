import React from 'react'
import classnames from "classnames"

const Title = ({ main, subTitle, options, _private }) => {
  if (!options)
    options = {
      center: false
    }

  let titleClassNames = classnames({ "text-center": options.center }, "font-normal", "text-4xl"),
    subTitleClassNames = classnames({ "text-center": options.center }, "font-normal", "mb-2"),
    containerClassNames = classnames(_private ? "lg:col-start-1 col-start-2" : "col-start-2 lg:col-start-3", "col-span-12", _private ? "lg:mt-40 mt-5" : "lg:mt-32 mt-24", "mb-5")

  if (subTitle && Array.isArray(subTitle)) {
    subTitle = subTitle.map((sub, i) => <h4 key={i} className={subTitleClassNames}>{sub}</h4>)
  }
  else if (subTitle && !Array.isArray(subTitle))
    subTitle = <h4 className={subTitleClassNames}>{subTitle}</h4>

  return (
    <div className={containerClassNames}>
      <h1 className={titleClassNames}>{main}</h1>
      {subTitle}
    </div>
  )
}

export default Title

