import React from 'react'

/**
 * Modular F.A.Q item
 * for the F.A.Q page
 * @param {*} param0 
 */
const FAQItem = ({ question, answer, last }) => (
    <>
        <div className="col-start-2 col-span-10 lg:col-start-3 lg:col-span-3">
            <h2 className="text-2xl font-medium py-4">{question}</h2>
        </div>
        <div className="col-start-2 col-span-10 lg:col-start-7 lg:col-span-4 text-justify">
            <p className="text-grayText text-sm py-4">{answer}</p>
        </div>
        <div className="col-span-12 lg:col-start-3 lg:col-span-4 lg:col-end-11">
            {!last && (<hr />)}
        </div>

    </>
)

export default FAQItem

