import React from "react"

// Components
import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import Title from "../components/global/title"
import FAQItem from "../components/faq/item"


// Static data
import faqData from "../data/faq.json"

const FaqPage = () => (
  <Layout>
    <SEO title="faq" />
    <div className="grid grid-cols-12">
      <Title main="Preguntas frecuentes" />
      {faqData && faqData.data.length && (
        faqData.data.map((f, i, a) => {
          return <FAQItem question={f.question} answer={f.answer} last={i === a.length - 1} />
        })
      )}
    </div>
  </Layout>
)

export default FaqPage
